import { createContext } from "react";

export interface IError {
    message: string;
}

export enum ActionType {
    ERROR,
    SET_RESOURCE,
    SET_LOADING,
    SET_RESOURCES,
    SET_FILTERED_DPS,
    SET_REQUEST_RESOURCE
}

export enum Process {
    INDIVIDUAL,
    BULK
}

export interface IDecode {
    type: string,
    content: {
        data: string;
        data_processor: { key: string };
        process: string
    }
}

export interface IAction<T> {
    kind: ActionType;
    rqResource?: IDecode;
    resource?: [];
    resources?: {};
    dataFilteredProcessors?: Record<string, { commonName: string }>;
    errors?: IError[];
    loading?: boolean;
}

export interface IContext {
    errors?: IError[];
    loading?: boolean;
    rqResource?: IDecode;
    // list of permissions for a DP
    resource?: []
    // object with data processors
    resources?: {}
    // data processors filtered by permission data
    dataFilteredProcessors?: Record<string, { commonName: string }>;
    [other: string]: any;
    getDataProcessors(...args: any): void;
    getDataProcessorsWithData(...args: any): void;
    permissionsForDP(...args: any): void;
    grantPermission(...args: any): void;
    grantPermissionForDataFromDP(...args: any): void;
    grantPermissionForBulkData(...args: any): void;
    decodePermission(...args: any): void;
    editPermission(...args: any): void;
    editBulkDataForPermission(...args: any): void;
    denyPermission(...args: any): void;
}

export interface Permission {
    hash: string,
    data_processor: string,
    data: string,
    process: string,
    reason: string,
    legal_policy: string,
    access_from: string,
    acces_until: string,
    access_amount: string
}

export type ContextType = IContext;
export const initialState: ContextType = {
    getDataProcessors: () => null,
    getDataProcessorsWithData: (data: string)=> Promise.reject,
    grantPermission: (hash: string) => Promise.reject,
    grantPermissionForDataFromDP: (hash: string) => Promise.reject,
    grantPermissionForBulkData: (hash: string) => Promise.reject,
    deletePermission: (hash: string) => Promise.reject,
    permissionsForDP: (hash: string) => Promise.reject,
    decodePermission: (hash: string) => Promise.reject,
    editPermission: (hash: string) => Promise.reject,
    editBulkDataForPermission: (hash: string) => Promise.reject,
    denyPermission: (hash: string) => Promise.reject,
    rqResource: undefined,
    resource: undefined,
    resources: undefined,
    dataFilteredProcessors: undefined,
    loading: false,
}

const PermissionContext = createContext(initialState);
export default PermissionContext;