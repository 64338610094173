import React from "react";
import "./DataActionsBar.css";
import CloseIcon from '@material-ui/icons/Close';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as EditSVG } from "../Icons/icons_edit.svg"
import { useHistory } from "react-router-dom";

interface IActionBar {
    goBack?: () => void         // go to previous page
    goNext?: () => void         // go to next page
    finishString: () => string  // string for final step
    edit?: (index: number) => void  // enable edit button
    cancel?: (index: number) => void         // cancel the action currently displayed
    index: number
}

export default function DataActionsBar(props: IActionBar) {

  const history = useHistory();

  return (
        <div className="DataActionsBar">
            <div className="DataActionsBarButton" onClick={() => props.cancel && props.cancel(props.index)}>
                <div className="DataActionsBarIcon DataActionsBarIcon-Side">
                    <CloseIcon style={{ fill: "red" }} />
                </div>
                <p className="DataActionsBarText">Remove Access</p>
            </div>

            <div className="DataActionsBarButton"
                 onClick={() => props.edit && props.edit(props.index)}
                 style={{ display: props.edit ? "flex" : "none"}}
            >
                <div className="DataActionsBarIcon DataActionsBarIcon-Middle">
                    <SvgIcon style={{ fill: "black" }}>
                        <EditSVG />
                    </SvgIcon>
                </div>
                <p className="BottomNavText">Edit</p>
            </div>

            <div className="DataActionsBarButton" onClick={props.goNext}>
                <div className="DataActionsBarIcon DataActionsBarIcon-Side">
                    <ArrowForwardIcon style={{ fill: "green" }} />
                </div>
                <p className="DataActionsBarText">Review Terms</p>
            </div>
        </div>
  );
}