import { makeStyles, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { IField } from './DataProcessor/Data';
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        '& .MuiFormLabel-root': {
            fontSize: '20px'
        },
        '& .MuiInputBase-root': {
            fontSize: '17px'
        },
    },
    container: {
        display: "flex", 
        flexDirection: "row", 
        justifyContent: "space-between", 
        alignItems: "center", 
        marginBottom: "15px"
    },
    containerLeft: {
        display: "flex", 
        flexDirection: "row", 
        alignItems: "center"
    },
    typographyContainer: {
        display: "flex", 
        flexDirection: "column", 
        alignItems: "flex-start", 
        justifyContent: "center" 
    }
}));

export default function EditForm(props: any) {

    const classes = useStyles();
    const data = JSON.parse(JSON.stringify(props.data))
    const [inputState, setInputState] = useState<[IField]>(data)
    const { register, handleSubmit, watch, errors } = useForm<any>({ mode: 'onBlur' });
    console.log(props)

    const makeTitle = (name: string) => {
        return <div className={classes.container}>
            <div className={classes.containerLeft}>
                <img src={props.img} width="25" height="25" style={{ background: "none", margin: "5px" }} />
                <div className={classes.typographyContainer}>
                    <Typography variant="body2" style={{ fontSize: "12px" }}>Shared with</Typography>
                    <Typography variant="body1" style={{ fontSize: "15px" }}>{name}</Typography>
                </div>
            </div>
            <h4 style={{ color: "#007293" }}>Update Data</h4>
        </div>
    }

    const onSubmit = (formData: any) => {
        props.submitHandler(inputState, formData)
    }

    // change from state when input field has changed
    const handleChange = (event: any) => {
        const newState: any = inputState;
        const index = newState.findIndex((x: IField) => x.name === event.target.id) // find matching id in state array
        newState[index].value = event.target.value; // set new value on that index
        setInputState(newState)
    }

    // return true when there is error on a field
    function getFieldError(uuid: string) {
        if (errors[uuid]) {
            return true;
        }
        return false;
    }

    // return the string accociated with an error
    function getErrorText(uuid: string) {
        if (errors[uuid]) {
            return errors[uuid].message;
        }
        return ''
    }

    function getRegister(entry: any) {
        if (entry.mime.includes("text")) {
            return {
                required: 'This field is required',
                minLength: {
                    value: 2,
                    message: 'Too short'
                }
            }
        } else {
            return {
                required: 'This field is required',
            }
        }
    }

    const dataEntry = (entries: any) => {
        return (
            <div style={{ margin: "20px" }}>
                {makeTitle(props.name)}
                <form id="my-form" className={classes.root} onSubmit={handleSubmit(onSubmit)} >{
                    entries.map((entry: IField) =>
                        <TextField
                            error={getFieldError(entry.uuid)}
                            helperText={getErrorText(entry.uuid)}
                            style={{ width: "95%" }}
                            onChange={handleChange}
                            name={entry.uuid}
                            inputRef={register(getRegister(entry)
                            )}
                            id={(entry.name).slice()}
                            label={(entry.name).slice()}
                            defaultValue={entry.value ? entry.value : ""}
                        />
                    )
                }
                </form>
            </div>
        )
    }

    return (
        dataEntry(data)
    )
}
