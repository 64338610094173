import React, {useContext, useEffect, useState} from 'react'
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import DataProcessorRequest from '../DataProcessor/DataRequest';
import useNotification from '../Notifications/NotificationHook';
import PermissionContext, {Process} from '../Permissions/Context'
import { CircularProgress } from '@material-ui/core';

const MessageDecoder = () => {
    const [b64PermissionReqMessage, setB64PermissionReqMessage] = useState<string>();
    const [enforcedFileName, setEnforcedFileName] = useState<string>();
    const history = useHistory();
    const {
        rqResource, dataFilteredProcessors,
        decodePermission, getDataProcessorsWithData, permissionsForDP
    } = useContext(PermissionContext)
    const [loading, setLoading] = useState(true)
    const [sourceDataProcessors, setSourceDataProcessors] = useState<any[]>()
    const { addNotification } = useNotification()
    const location = useLocation()

    const handleError = (err: any) => {
        console.error(err)
    }

     const goToRequestPage = () => {
        // get permissions for the DP in the current permission request to see if it already has permission
        // for that data, if it has & BULK flow, call editData (upload new file for the data)
        // See permissionForCurrentRequest in DataRequest.tsx
        if (rqResource) {
            permissionsForDP(rqResource.content.data_processor.key)
        }
        setLoading(false);
        history.push("/decode/new-request")
    }

    useEffect(() => {
        const queryParameters = new URLSearchParams(location.search)
        if (queryParameters.has("fileName")) {
            setEnforcedFileName(queryParameters.get("fileName") || "")
        }
        if (queryParameters.has("message")) {
            setB64PermissionReqMessage(queryParameters.get("message") || "")
        }
    }, [location])

    useEffect(() => {
        if (!b64PermissionReqMessage) {
            return;
        }
        decodePermission(b64PermissionReqMessage);
    }, [b64PermissionReqMessage]);

    useEffect(() => {
        if (!rqResource) return;

        if (rqResource.type === "correlation") {
            setLoading(false)
            addNotification("Successfully added Data Processor", 200)
            history.push('/dashboard')
        } else if (rqResource.type === "permission") {
            if (Process.BULK === Process[rqResource.content.process as keyof typeof Process]) {
                goToRequestPage()
            } else if (dataFilteredProcessors) {
                setSourceDataProcessors(
                    Object.entries(dataFilteredProcessors)
                        .filter(([key, _]) => key !== rqResource?.content.data_processor.key)
                        .map(
                           ([key, { commonName }]) => ({ name: commonName, code: key})
                        )
                )
            } else {
                getDataProcessorsWithData(rqResource.content.data)
            }
        } else {
            addNotification("Unknown response type", 300)
        }
    }, [rqResource])

    useEffect(() => {
     if (dataFilteredProcessors) {
         setSourceDataProcessors(
             Object.entries(dataFilteredProcessors)
                 .filter(([key, _]) => key !== rqResource?.content.data_processor.key)
                 .map(
                    ([key, { commonName }]) => ({ name: commonName, code: key})
                 )
         )
     }
    }, [dataFilteredProcessors])

    useEffect(() => {
        if (rqResource && sourceDataProcessors) {
            goToRequestPage()
        }
    }, [sourceDataProcessors])

    return (
        <>
            { loading ?
                <CircularProgress style={{ marginTop: "20px" }} />
                :
                <Switch>
                    <Route path="/decode/new-request">
                        <DataProcessorRequest
                            permissionMessage={b64PermissionReqMessage}
                            enforcedFileName={enforcedFileName}
                            sourceDataProcessors={sourceDataProcessors}
                        />
                    </Route>
                    <Route path="/decode" component={MessageDecoder} />
                </Switch>
            }
        </>
    )
}

export default MessageDecoder;
